import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';
import { useAuthStore } from '@/store/auth';

function getRedirectLocation(
    path: '/login' | '/signup/plans',
    to: RouteLocationNormalized,
): RouteLocationRaw {
    const result: RouteLocationRaw = {
        path,
        query: {
            ...to.query,
            redirect: to.path,
        }
    };
    return result;
}

export default defineNuxtRouteMiddleware((to) => {
    const authStore = useAuthStore();
    const authCookie = useAuthCookie();
    if (!authCookie) {
        return getRedirectLocation('/login', to);
    }
    const token = authCookie.accessToken;
    authStore.authenticate(!!token);
    authStore.parseJWT(token);
    if (!authStore.isAuthenticated || !authStore.isActive) {
        return getRedirectLocation('/login', to);
    }
    if (authStore.isSubscription) {
        setTimeout(() => {
            if (!authStore.userData) {
                authStore.fetchUser();
            }
        }, 100);
    } else {
        return getRedirectLocation('/signup/plans', to);
    }
});
